import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import Layout from "../components/layout"

const ProjectsPage = ({ data }) => (
   <Layout>
      <Masonry className="showcase">
      {data.allMdx.edges.map(({ node }) => (
         <div key={node.id} className="showcase__item">
            <figure className="card">
               <Link to={`/${node.fields.slug}`} className="card__image">
               <Img fluid={node.fields.thumbnail.childImageSharp.fluid} />
               </Link>
               <figcaption className="card__caption">
                  <h6 className="card__title">
                     <Link to={`/${node.fields.slug}`}>{node.frontmatter.title}</Link>
                  </h6>
                  <div className="card__description">
                     <p>{node.frontmatter.summary}</p>
                  </div>
               </figcaption>
            </figure>
         </div>
      ))}
      </Masonry>
   </Layout>
)

export default ProjectsPage

export const query = graphql`
   query {
      allMdx(
         filter: {frontmatter: {published: {eq: true}, type: {eq: "project"}}},
         sort: { fields: [frontmatter___date], order: DESC }) {
         totalCount
         edges {
            node {
               id
               frontmatter {
                  title
                  date(formatString: "DD MMMM, YYYY")
                  summary
               }
               fields {
                  name
                  slug
                  thumbnail {
                     childImageSharp {
                        fluid(maxWidth: 450) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
         }
      }
   }
`